import {Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';
import TildaCompanyPage from "./pages/tildaCompany/TildaCompanyPage";
import VkCompanyPage from "./pages/vkCompany/VkCompanyPage";
import ZvonokCompanyPage from "./pages/zvonokCompany/ZvonokCompanyPage";
import Layout from "./pages/shared/Layout";
import '@mantine/core/styles.css';
import 'mantine-react-table/styles.css';
import '@mantine/dates/styles.css';
import AuthStore from "./pages/auth/store/AuthStore";
import {AuthorizationPage} from "./pages/auth/AuthorizationPage";
import VkAdsPage from "./pages/vkAds/VkAdsPage";


function App() {
    return (
        !AuthStore.updateAccount() ? (
            <Layout>
                <Routes>
                    <Route path="*" element={<Suspense>
                        <AuthorizationPage/>
                    </Suspense>}/>
                </Routes>
            </Layout>
        ) : (
            <Layout>
                <Routes>
                    <Route path="/*" element={<Suspense>
                        <ZvonokCompanyPage/>
                    </Suspense>}/>
                    <Route path="/tildaCompany" element={<Suspense>
                        <TildaCompanyPage/>
                    </Suspense>}/>
                    <Route path="/vkCompany" element={<Suspense>
                        <VkCompanyPage/>
                    </Suspense>}/>
                    <Route path="/vkAds" element={<Suspense>
                        <VkAdsPage/>
                    </Suspense>}/>
                </Routes>
            </Layout>
        )
    )
}

export default App;