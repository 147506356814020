import {Select} from "@mantine/core";
import {useEffect, useState} from "react";
import {ZvonokCompanyPageModel} from "../../pages/zvonokCompany/ZvonokCompanyPageModel";
import {getRequest} from "../helper/Request";

interface Props {
    value: number;
    onChange: React.Dispatch<React.SetStateAction<number>>;
}

interface ComboboxItem {
    label: string;
    value: string;
}

export const SelectorZvonok = (props: Props) => {
    const [models, setModels] =
        useState<ComboboxItem[]>();

    useEffect(() => {
        requestInit()
    }, []);

    const setSelect = (value: string | null, option: ComboboxItem) => {
        if (value !== null && value !== undefined) {
            props.onChange(parseInt(value))
        }
    }

    async function requestInit() {
        getRequest<ZvonokCompanyPageModel[]>("api/ZvonokCompany/list")
            .then(x => {
                setModels(x.Model?.map(x =>
                    ({
                        label: x.name,
                        value: x.id.toString()
                    })) ?? []);
            })
    }

    return <Select label={"Название кампании"} value={props.value?.toString() ?? ""}
                   searchable
                   data={models} onChange={setSelect}/>
}