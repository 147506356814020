import {CustomTable} from "../../components/customTable/CustomTable";
import {TableType} from "../../components/customTable/TableType";
import {Button, Checkbox, Modal, TextInput} from "@mantine/core";
import {useDisclosure, useInputState} from "@mantine/hooks";
import {useEffect, useState} from "react";
import {getRequest, getRequestResponse, postRequestRequest} from "../../components/helper/Request";
import {SelectorZvonok} from "../../components/selectorZvonok/SelectorZvonok";
import {VkAdsPageModel} from "./VkAdsPageModel";


function VkAdsPage() {
    const [opened, {open, close}] = useDisclosure(false);
    const [openedDelete, deleteOpen] = useDisclosure(false);
    const [models, setModels] =
        useState<VkAdsPageModel[]>();

    const [id, setId] =
        useState<number>(0);
    const [inputName, setInputName] = useInputState('');
    const [vkId, setVkId] = useInputState('');
    const [sync, setSync] = useInputState<boolean>(false);
    const [companyZvonok, setCompanyZvonok] = useInputState<number | undefined>(undefined);
    const [leadIds, setLeadIds] = useInputState('');

    const [deleteName, setDeleteName] = useState('');

    useEffect(() => {
        requestInit()
    }, []);

    const openUpdateModal = (id: number) => {
        setId(id)
        const model = models?.filter(x => x.id === id)[0];
        if (!model)
            return;
        setInputName(model.name)
        setVkId(model.vkId)
        setSync(model.sync)
        setCompanyZvonok(model.companyZvonokId)
        setLeadIds(model.leadIds)
        open();
    }

    const deleteModel = (id: number) => {
        setId(id)
        const model = models?.filter(x => x.id === id)[0];
        if (!model)
            return;
        setDeleteName(model.name);
        deleteOpen.open();
    }

    return (
        <div>
            <Modal opened={opened} onClose={close} title={"Обновить"}>
                <TextInput value={inputName} onChange={setInputName} label="Название" placeholder="Название"/>
                <TextInput className={"marginTop16"} value={vkId}
                           onChange={setVkId}
                           label="Идентификатор ВК"
                           placeholder="Идентификатор ВК"/>
                <Checkbox
                    className={"marginTop16"}
                    label={"Синхронизация"}
                    checked={sync}
                    onChange={(event) => setSync(event.currentTarget.checked)}
                />
                <SelectorZvonok value={companyZvonok} onChange={setCompanyZvonok}/>
                <TextInput className={"marginTop16"} value={leadIds}
                           onChange={setLeadIds}
                           label="Список идентификаторов форм"
                           placeholder="Список идентификаторов форм"/>
                <Button className={"marginTop16"} onClick={save}>Сохранить</Button>
            </Modal>
            <Modal opened={openedDelete} onClose={deleteOpen.close} title={"Удалить"}>
                <h1>{deleteName}</h1>
                <Button style={{marginTop: '16px'}} onClick={deleteRequest}>Удалить</Button>
            </Modal>

            <h1 id="tabelLabel">VK Реклама</h1>
            <CustomTable columns={models}
                         type={TableType.vkAds}
                         onclick={openUpdateModal}
                         delete={deleteModel}
            />
        </div>
    );

    async function save() {
        postRequestRequest<VkAdsPageModel>("api/VkAdsLead/update", {
            id: id,
            name: inputName,
            vkId: vkId,
            sync: sync,
            companyZvonokId: companyZvonok,

            countAll: 0,
            countLastMonth: 0,
            countPrevDay: 0,
            leadIds: leadIds,
        }).then(x => {
            requestInit();
            close();
        });
    }

    async function deleteRequest() {
        getRequestResponse("api/VkAdsLead/delete/" + id)
            .then(x => {
                requestInit()
                deleteOpen.close();
            });
    }

    async function requestInit() {
        getRequest<VkAdsPageModel[]>("api/VkAdsLead/list")
            .then(x => {
                setModels(x.Model ?? []);
            })
    }
}

export default VkAdsPage;