import {CustomTable} from "../../components/customTable/CustomTable";
import {TableType} from "../../components/customTable/TableType";
import {Button, Modal, TextInput} from "@mantine/core";
import {useDisclosure, useInputState} from "@mantine/hooks";
import {useEffect, useState} from "react";
import {getRequest, getRequestResponse, postRequestRequest} from "../../components/helper/Request";
import {ZvonokCompanyPageModel} from "./ZvonokCompanyPageModel";


function TildaCompanyPage() {
    const [opened, {open, close}] = useDisclosure(false);
    const [openedDelete, deleteOpen] = useDisclosure(false);
    const [models, setModels] =
        useState<ZvonokCompanyPageModel[]>();

    const [id, setId] =
        useState<number>(0);
    const [inputName, setInputName] = useInputState('');
    const [companyId, setCompanyId] = useInputState('');

    const [deleteName, setDeleteName] = useState('');

    useEffect(() => {
        requestInit()
    }, []);

    const openModal = () => {
        setId(0)
        setInputName('')
        setCompanyId('')
        open();
    }
    const openUpdateModal = (id: number) => {
        setId(id)
        const model = models?.filter(x => x.id === id)[0];
        if (!model)
            return;
        setInputName(model.name)
        setCompanyId(model.companyId)
        open();
    }

    const deleteModel = (id: number) => {
        setId(id)
        const model = models?.filter(x => x.id === id)[0];
        if (!model)
            return;
        setDeleteName(model.name);
        deleteOpen.open();
    }

    return (
        <div>
            <Modal opened={opened} onClose={close} title={"Добавить компании"}>
                <TextInput value={inputName} onChange={setInputName} label="Название" placeholder="Название"/>
                <TextInput value={companyId} onChange={setCompanyId} label="Идентификатор кампании"
                           placeholder="Идентификатор кампании"/>
                <Button style={{marginTop: '16px'}} onClick={save}>Сохранить</Button>
            </Modal>
            <Modal opened={openedDelete} onClose={deleteOpen.close} title={"Удалить"}>
                <h1>{deleteName}</h1>
                <Button style={{marginTop: '16px'}} onClick={deleteRequest}>Удалить</Button>
            </Modal>

            <h1 id="tabelLabel">Zvonok Кампания</h1>
            <CustomTable columns={models}
                         type={TableType.zvonok}
                         add={openModal}
                         onclick={openUpdateModal}
                         delete={deleteModel}
            />
        </div>
    );

    async function save() {
        postRequestRequest<ZvonokCompanyPageModel>("api/ZvonokCompany/update", {
            id: id,
            name: inputName,
            companyId: companyId,

            countAll: 0,
            countLastMonth: 0,
            countPrevDay: 0
        }).then(x => {
            requestInit();
            close();
        });
    }

    async function deleteRequest() {
        getRequestResponse("api/ZvonokCompany/delete/" + id)
            .then(x => {
                requestInit()
                deleteOpen.close();
            });
    }

    async function requestInit() {
        getRequest<ZvonokCompanyPageModel[]>("api/ZvonokCompany/list")
            .then(x => {
                setModels(x.Model ?? []);
            })
    }
}

export default TildaCompanyPage;