import {MRT_ColumnDef} from "mantine-react-table/dist";
import {TableType} from "./TableType";


// const maxValue = (items?: number[]) => {
//     return Math.max(...items?.filter(x => x) ?? [0]) ?? 0;
// }
// const minValue = (items?: number[]) => {
//     return Math.min(...items?.filter(x => x) ?? [0]) ?? 0;
// }

export function GetColumns(columns: object[] | undefined, type: TableType): MRT_ColumnDef<object>[]{
    if (type === TableType.vk)
        return [
            {
                accessorKey: 'id',
                header: 'Идентификатор',
            },
            {
                accessorKey: 'name',
                header: 'Название',
            },
            {
                accessorKey: 'groupId',
                header: 'Идентификатор группы',
            },
            {
                accessorKey: 'responseConfirm',
                header: 'Ответ от ВК',
            },
            {
                accessorKey: 'companyZvonok',
                header: 'Кампания в звонке',
            },
            {
                accessorKey: 'leadIds',
                header: 'Список идентификаторов форм',
            },
            {
                accessorKey: 'сountToday',
                header: 'Кол-во за сегодня, запросов/отправлено',
                accessorFn: (row) => `${row.countToday}/${row.countSendToday}`,
            },
            {
                accessorKey: 'countPrevDay',
                header: 'Кол-во за вчера, запросов/отправлено',
                accessorFn: (row) => `${row.countPrevDay}/${row.countSendPrevDay}`,
            },
            {
                accessorKey: 'countLastMonth',
                header: 'Кол-во за коллендарный месяц, запросов/отправлено',
                accessorFn: (row) => `${row.countLastMonth}/${row.countSendLastMonth}`,
            },
            {
                accessorKey: 'countAll',
                header: 'Всего/Всего отправленно',
                accessorFn: (row) => `${row.countAll}/${row.countSendAll}`,
            },
        ]
    if (type === TableType.tilda)
        return [
            {
                accessorKey: 'id',
                header: 'Идентификатор',
            },
            {
                accessorKey: 'name',
                header: 'Название',
            },
            {
                accessorKey: 'code',
                header: 'Код',
            },
            {
                accessorKey: 'companyZvonok',
                header: 'Кампания в звонке',
            },
            {
                accessorKey: 'сountToday',
                header: 'Кол-во за сегодня, запросов/отправлено',
                accessorFn: (row) => `${row.countToday}/${row.countSendToday}`,
            },
            {
                accessorKey: 'countPrevDay',
                header: 'Кол-во за вчера, запросов/отправлено',
                accessorFn: (row) => `${row.countPrevDay}/${row.countSendPrevDay}`,
            },
            {
                accessorKey: 'countLastMonth',
                header: 'Кол-во за коллендарный месяц, запросов/отправлено',
                accessorFn: (row) => `${row.countLastMonth}/${row.countSendLastMonth}`,
            },
            {
                accessorKey: 'countAll',
                header: 'Всего/Всего отправленно',
                accessorFn: (row) => `${row.countAll}/${row.countSendAll}`,
            },
        ]
    if (type === TableType.zvonok)
        return [
            {
                accessorKey: 'id',
                header: 'Идентификатор',
            },
            {
                accessorKey: 'name',
                header: 'Название',
            },
            {
                accessorKey: 'companyId',
                header: 'Идентификацию кампанию',
            }
        ]
    if (type === TableType.vkAds)
        return [
            {
                accessorKey: 'id',
                header: 'Идентификатор',
            },
            {
                accessorKey: 'name',
                header: 'Название',
            },
            {
                accessorKey: 'vkId',
                header: 'Идентификатор вк',
            },
            {
                accessorKey: 'sync',
                header: 'Синхронизация',
                Cell: ({ cell }) => cell.getValue() === true ? "Работает" : "Не работает"
            },
            {
                accessorKey: 'companyZvonok',
                header: 'Кампания в звонке',
            },
            {
                accessorKey: 'сountToday',
                header: 'Кол-во за сегодня, запросов/отправлено',
                accessorFn: (row) => `${row.countToday}/${row.countSendToday}`,
            },
            {
                accessorKey: 'countPrevDay',
                header: 'Кол-во за вчера, запросов/отправлено',
                accessorFn: (row) => `${row.countPrevDay}/${row.countSendPrevDay}`,
            },
            {
                accessorKey: 'countLastMonth',
                header: 'Кол-во за коллендарный месяц, запросов/отправлено',
                accessorFn: (row) => `${row.countLastMonth}/${row.countSendLastMonth}`,
            },
            {
                accessorKey: 'countAll',
                header: 'Всего/Всего отправленно',
                accessorFn: (row) => `${row.countAll}/${row.countSendAll}`,
            },
        ]
    return [];
}