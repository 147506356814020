import {CustomTable} from "../../components/customTable/CustomTable";
import {TableType} from "../../components/customTable/TableType";
import {Button, Modal, TextInput} from "@mantine/core";
import {useDisclosure, useInputState} from "@mantine/hooks";
import {useEffect, useState} from "react";
import {getRequest, getRequestResponse, postRequestRequest} from "../../components/helper/Request";
import {TildaCompanyPageModel} from "./TildaCompanyPageModel";
import {SelectorZvonok} from "../../components/selectorZvonok/SelectorZvonok";


function TildaCompanyPage() {
    const [opened, {open, close}] = useDisclosure(false);
    const [openedDelete, deleteOpen] = useDisclosure(false);
    const [models, setModels] =
        useState<TildaCompanyPageModel[]>();

    const [id, setId] =
        useState<number>(0);
    const [inputName, setInputName] = useInputState('');
    const [code, setCode] = useInputState('');
    const [companyZvonok, setCompanyZvonok] = useInputState<number>(0);

    const [deleteName, setDeleteName] = useState('');

    useEffect(() => {
        requestInit()
    }, []);

    const openModal = () => {
        setId(0)
        setInputName('')
        setCode('')
        setCompanyZvonok('')
        open();
    }
    const openUpdateModal = (id: number) => {
        setId(id)
        const model = models?.filter(x => x.id === id)[0];
        if (!model)
            return;
        setInputName(model.name)
        setCode(model.code)
        setCompanyZvonok(model.companyZvonokId)
        open();
    }

    const deleteModel = (id: number) => {
        setId(id)
        const model = models?.filter(x => x.id === id)[0];
        if (!model)
            return;
        setDeleteName(model.name);
        deleteOpen.open();
    }

    return (
        <div>
            <Modal opened={opened} onClose={close} title={id === 0 ? "Добавить кампании" : "Обновить кампанию"}>
                <TextInput value={inputName} onChange={setInputName} label="Название" placeholder="Название"/>
                <TextInput value={code} onChange={setCode} label="Код"
                           placeholder="Код"/>
                <SelectorZvonok value={companyZvonok} onChange={setCompanyZvonok} />
                <Button style={{marginTop: '16px'}} onClick={save}>Сохранить</Button>
            </Modal>
            <Modal opened={openedDelete} onClose={deleteOpen.close} title={"Удалить"}>
                <h1>{deleteName}</h1>
                <Button style={{marginTop: '16px'}} onClick={deleteRequest}>Удалить</Button>
            </Modal>

            <h1 id="tabelLabel">Tilda Кампания</h1>
            <CustomTable columns={models}
                         type={TableType.tilda}
                         add={openModal}
                         onclick={openUpdateModal}
                         delete={deleteModel}
            />
        </div>
    );

    async function save() {
        postRequestRequest<TildaCompanyPageModel>("api/TildaCompany/update", {
            id: id,
            name: inputName,
            companyZvonokId: companyZvonok,
            code: code,

            countAll: 0,
            countLastMonth: 0,
            countPrevDay: 0
        }).then(x => {
            requestInit();
            close();
        });
    }

    async function deleteRequest() {
        getRequestResponse("api/TildaCompany/delete/" + id)
            .then(x => {
                requestInit()
                deleteOpen.close();
            });
    }

    async function requestInit() {
        getRequest<TildaCompanyPageModel[]>("api/TildaCompany/list")
            .then(x => {
                setModels(x.Model ?? []);
            })
    }
}

export default TildaCompanyPage;