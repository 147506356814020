import {LoadingModel} from "./LoadingModel";

let baseUrl = 'https://autoschool-tech.ru/';
// let baseUrl = 'http://localhost:5012/';


export async function getRequest<T>(link: string): Promise<LoadingModel<T>> {
    let req = {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
            "Access-Control-Allow-Origin": "*"
        },
    };
    let res: LoadingModel<T> = new LoadingModel<T>();
    try {
        let response = await fetch((baseUrl + link), req);
        if (response.ok) {
            res.setModel(await response.json());
            return res;
        }
        if (response.status === 401) {
            res.setErrorM(await response.json())
            return res;
        }
        if (response.status === 502) {
            throw new Error("На сайте ведутся технические работы. Закончатся в ближайшее время");
        }
    } catch (e) {
        res.setError((e as Error).message);
    }

    return res;
}

export async function getRequestResponse(link: string): Promise<Response> {
    let req = {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
            // 'Content-Type': 'application/json'
        },
    };
    return await fetch((baseUrl + link), req);
}

export async function downloadFile(link: string): Promise<Response> {
    let req = {
        method: "GET",
        headers: {
            "Accept": "binary/octet-stream",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
            // 'Content-Type': 'application/json'
        },
    };
    return await fetch((baseUrl + link), req);
}

export async function postRequest<T, E>(link: string, body: E): Promise<LoadingModel<T>> {
    let req = {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    };
    let res: LoadingModel<T> = new LoadingModel<T>();
    let response = await fetch((baseUrl + link), req);
    if (response.ok) {
        res.setModel(await response.json());
        return res;
    }
    if (response.status === 401) {
        res.setErrorM(await response.json())
        return res;
    }
    res.setError(response.statusText);
    return res;
}

export async function postFormRequest<T>(link: string, body: FormData): Promise<LoadingModel<T>> {
    let req = {
        method: "POST",
        body: body,
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    };
    let res: LoadingModel<T> = new LoadingModel<T>();
    let response = await fetch((baseUrl + link), req);
    if (response.ok) {
        res.setModel(await response.json());
        return res;
    }
    if (response.status === 401) {
        res.setErrorM(await response.json())
        return res;
    }
    res.setError(response.statusText);
    return res;
}

export async function postRequestRequest<E>(link: string, body: E): Promise<Response> {
    let req = {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    };
    return await fetch((baseUrl + link), req);
}