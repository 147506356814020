import {CustomTable} from "../../components/customTable/CustomTable";
import {TableType} from "../../components/customTable/TableType";
import {Button, Modal, TextInput} from "@mantine/core";
import {useDisclosure, useInputState} from "@mantine/hooks";
import {useEffect, useState} from "react";
import {getRequest, getRequestResponse, postRequestRequest} from "../../components/helper/Request";
import {VkCompanyPageModel} from "./VkCompanyPageModel";
import {SelectorZvonok} from "../../components/selectorZvonok/SelectorZvonok";


function VkCompanyPage() {
    const [opened, {open, close}] = useDisclosure(false);
    const [openedDelete, deleteOpen] = useDisclosure(false);
    const [models, setModels] =
        useState<VkCompanyPageModel[]>();

    const [id, setId] =
        useState<number>(0);
    const [inputName, setInputName] = useInputState('');
    const [groupId, setGroupId] = useInputState('');
    const [leadIds, setLeadIds] = useInputState('');
    const [responseConfirm, setResponseConfirm] = useInputState('');
    const [companyZvonok, setCompanyZvonok] = useInputState<number>(0);

    const [deleteName, setDeleteName] = useState('');

    useEffect(() => {
        requestInit()
    }, []);

    const openModal = () => {
        setId(0)
        setInputName('')
        setGroupId('')
        setResponseConfirm('')
        setCompanyZvonok(0)
        open();
    }
    const openUpdateModal = (id: number) => {
        setId(id)
        const model = models?.filter(x => x.id === id)[0];
        if (!model)
            return;
        setInputName(model.name)
        setGroupId(model.groupId)
        setResponseConfirm(model.responseConfirm)
        setCompanyZvonok(model.companyZvonokId)
        setLeadIds(model.leadIds)
        open();
    }

    const deleteModel = (id: number) => {
        setId(id)
        const model = models?.filter(x => x.id === id)[0];
        if (!model)
            return;
        setDeleteName(model.name);
        deleteOpen.open();
    }

    return (
        <div>
            <Modal opened={opened} onClose={close} title={id === 0 ? "Добавить кампании" : "Обновить кампанию"}>
                <TextInput value={inputName} onChange={setInputName} label="Название" placeholder="Название"/>
                <TextInput value={groupId} onChange={setGroupId} label="Идентификатор группы"
                           placeholder="Идентификатор группы"/>
                <TextInput value={responseConfirm} onChange={setResponseConfirm} label="Ответ от ВК"
                           placeholder="Ответ от ВК"/>
                <SelectorZvonok value={companyZvonok} onChange={setCompanyZvonok}/>

                <TextInput className={"marginTop16"} value={leadIds}
                           onChange={setLeadIds}
                           label="Список идентификаторов форм"
                           placeholder="Список идентификаторов форм"/>
                <Button style={{marginTop: '16px'}} onClick={save}>Сохранить</Button>
            </Modal>
            <Modal opened={openedDelete} onClose={deleteOpen.close} title={"Удалить"}>
                <h1>{deleteName}</h1>
                <Button style={{marginTop: '16px'}} onClick={deleteRequest}>Удалить</Button>
            </Modal>

            <h1 id="tabelLabel">VK Кампания</h1>
            <CustomTable columns={models}
                         type={TableType.vk}
                         add={openModal}
                         onclick={openUpdateModal}
                         delete={deleteModel}
            />
        </div>
    );

    async function save() {
        postRequestRequest<VkCompanyPageModel>("api/VkCompany/update", {
            id: id,
            name: inputName,
            code: "",
            companyZvonokId: companyZvonok,
            responseConfirm: responseConfirm,
            groupId: groupId,

            countAll: 0,
            countLastMonth: 0,
            countPrevDay: 0,
            leadIds: leadIds
        }).then(x => {
            requestInit();
            close();
        });
    }

    async function deleteRequest() {
        getRequestResponse("api/VkCompany/delete/" + id)
            .then(x => {
                requestInit()
                deleteOpen.close();
            });
    }

    async function requestInit() {
        getRequest<VkCompanyPageModel[]>("api/VkCompany/list")
            .then(x => {
                setModels(x.Model ?? []);
            })
    }
}

export default VkCompanyPage;