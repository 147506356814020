import * as React from "react";
import {Group} from "@mantine/core";
import {Link, NavLink} from "react-router-dom";
import './layout.scss';
import AuthStore from "../auth/store/AuthStore";


interface Props{
    children?: React.ReactNode;
}
function Layout(props: Props) {
    return (
        <div className="layout-wrapper">
            <div className="layout_page">
                <Group className="menu_group">
                    <Link to="/"
                          className={`menu_button layout-title-wrapper`}>
                        <img className={"logo"} src="/sputnik-circle.png" alt="logo"/>
                        <div className="layout-title marginLeft16">Автошкола админка</div>
                    </Link>
                </Group>
                <Group className="right_group">
                    <div className="menu_list">
                        <NavLink to="/"
                              className={`menu_button`}>Звонок</NavLink>
                        <NavLink to="/vkCompany"
                              className={`menu_button`}>VK</NavLink>
                        <NavLink to="/vkAds"
                              className={`menu_button`}>VK реклама</NavLink>
                        <NavLink to="/tildaCompany"
                              className={`menu_button`}>Тильда</NavLink>
                        {
                            AuthStore.isAuth &&
                            <div onClick={AuthStore.signOut}>
                                Выйти
                            </div>
                        }
                    </div>
                </Group>
            </div>
            <div className="layout-content">
                {props.children}
            </div>
        </div>
    );
}

export default Layout;