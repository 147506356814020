import {GetColumns} from "./ColumHelper";
import {MantineReactTable} from "mantine-react-table";
import {TableType} from "./TableType";
import {MRT_Localization_RU} from "mantine-react-table/locales/ru";
import {Button} from "@mantine/core";


interface Props<T extends Record<string, object>> {
    columns: T[] | undefined;
    type: TableType;
    add?: () => void;
    delete: (id: number) => void;
    onclick: (id: number) => void;
}

export function CustomTable<T extends Record<string, object>>(props: Props<T>) {
    return <MantineReactTable
        initialState={{density: 'xs', pagination: { pageSize: 30,pageIndex: 0 }}}
        columns={GetColumns(props.columns ?? [], props.type)}
        data={props.columns ?? []}
        enableSorting={true}
        enableStickyHeader={true}
        enableTableHead={true}
        enableRowSelection={false}
        enableColumnResizing={true}
        enableColumnActions={false}
        enableRowActions={true}
        enableDensityToggle={true}
        enableFullScreenToggle={false}
        enableMultiRowSelection={false}
        enableSubRowSelection={false}
        localization={MRT_Localization_RU}
        mantinePaginationProps={{
            rowsPerPageOptions: ['30', '50', '100', '500'],
        }}
        renderRowActionMenuItems={({row}) => (
            <>
                <div onClick={() => props.onclick(parseInt(row.getValue("id")))}>Обновить</div>
                <div onClick={() => props.delete(parseInt(row.getValue("id")))}>Удалить</div>
            </>
        )}
        renderTopToolbarCustomActions={({table}) => (
            props.add &&
            <div style={{display: 'flex', alignItems: 'center', marginTop: '10px', width: '100%'}}>
                <Button onClick={props.add} style={{marginLeft: '20px !impotent'}}>Добавить кампанию</Button>
            </div>
        )}
    />
}